<template>
    <div class="pop-up lg:col-start-9 lg:col-end-12 text-center">
        <a aria-label="Close pop-up" class="js-pop-btn pop-up__close" href="javascript:;"><i class="fas fa-times-circle"></i></a>
        <p class="mb-8"><span class="label bg-yellow text-teal">{{item.lotStage}}</span><span class="label bg-teal text-yellow">{{item.name}}</span></p>
        <p class="lead mb-1"><b>{{item.lotSpec}} m<sup>2</sup> &ndash; 
            <vue-numeric currency="$" separator="," :precision="0" :value='item.lotPrice' read-only>
            </vue-numeric></b>
        </p>
        <p class="lead mb-6 lg:mb-8"><b>Available now</b></p>
        <p v-if="item.lotDocument != null" class="mb-6 lg:mb-8"><a class="link" :href="item.lotDocument" target="_blank">Key documents <i class="font-awesome font-bold text-lg fas fa-arrow-to-bottom ml-2"></i></a></p>
        <p><a :href="enquiry" class="button button--small">Enquire about this lot</a></p>
    </div>
</template>

<script>
import VueNumeric from 'vue-numeric'

export default {
    props: {
        item: {
            type: Object,
            default() {
                return {
                    lotSpec: '',
                    lotPrice: '',
                    lotDocument: '',
                    name: '',
                }
            }
        },
        enquiry: {
            type: String
        }
    },
    components: {
        VueNumeric
    },
    methods: {
       
    },
    data: function () {
        return {
        }
    }
}
</script>