import Vue from 'vue';
import VueResource from 'vue-resource';
import Lot from '../vue/lot';

let masterplan = document.querySelector('#masterplan');

Vue.use(VueResource);

if(masterplan != null) {

// Masterplan pop-ups
const popTrigger = document.querySelector('.js-pop-btn');
const popUp = document.querySelector('.pop-up');

// Intiate Vuejs instance
var app = new Vue({
    el: '#masterplan',
    delimiters: ["${","}"],
    components: {
        Lot
    },
    data: function () {
        return {
            items: [],
            currentLot: 1,
            rangeFrom: 1,
            rangeTo: 16,
            sold: []
        }
    },
    methods : {
        showLot(lotNumber) {
            return this.items[lotNumber-1];
        },
        removeActiveSectionLinks() {
            for (let sectionID = this.rangeFrom; sectionID <= this.rangeTo; sectionID++) {
                let lot = document.querySelector('#s-' + (sectionID));
                lot.classList.remove('section--active');
            }
        }
    },
    computed: {
        populatedItem() {
            return this.items[this.currentLot - 1]
        }
    },
    created: function() {
        let lots = this.$http.get('/api/v1/lots.json').then(function(data) {
            this.items = data.body.data;
        });   
        let range = this.$http.get('/api/v1/range.json').then(function(data) {
            this.rangeFrom = data.body.data[0].rangeFrom;
            this.rangeTo = data.body.data[0].rangeTo;
        }); 
        
        let sold = this.$http.get('/api/v1/lots.json').then(function(data) {
            data.body.data.forEach((item, index) => {
                if(!item.lotAvailable) {
                    this.sold.push(index + 1);
                }
            })
        }); 

        let that = this;
        Promise.all([lots, range, sold]).then(function(){
            for (let sectionID = that.rangeFrom; sectionID <= that.rangeTo; sectionID++) {
                let lot = document.querySelector('#s-' + (sectionID));

                if(!that.sold.includes(sectionID)) {
                    // Add available class
                    lot.classList.add('section--available');

                    lot.addEventListener('click', function(e) {
                        let lotNumber = (e.target.parentElement.id).split('-')[1];
                        that.currentLot = +lotNumber;

                        that.removeActiveSectionLinks();
                        lot.classList.add('section--active');
                        
                        document.querySelector('.pop-up').classList.add('pop-up--active');
                    });
                    
                    // Toggle hover states
                    lot.querySelector('.cls-5').addEventListener('mouseenter', function(e) {
                        lot.classList.toggle('section--hover');
                    });

                    lot.querySelectorAll('.cls-6').forEach(item => {
                        item.addEventListener('mouseenter', function(e) {
                            item.parentElement.classList.add('section--hover');
                        });
                    })

                    lot.querySelectorAll('.cls-6').forEach(item => {
                        item.addEventListener('mouseout', function(e) {
                            item.parentElement.classList.remove('section--hover');
                        });
                    })
                    
                    lot.querySelector('.cls-5').addEventListener('mouseout', function(e) {
                        lot.classList.toggle('section--hover');
                    });

                    
                } else {
                    lot.classList.add('section--sold');
                }
            }

            // Pop up mobile
            document.querySelector('.js-pop-btn').addEventListener('click', function(e) {
                document.querySelector('.pop-up').classList.toggle('pop-up--active');
                that.removeActiveSectionLinks();
            });
        })   
    }
})
}